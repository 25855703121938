<template>
  <div class="Mint">
    <div id="network">
      <p>0.02 ETH per CouplePunk.</p>
      <p>Total 100000 Punks Can be Minted and 700 of them ARE <span class="text-red-500">SUPER RARE!</span></p>
      <p>contract: <a href="https://etherscan.io/address/0x161c39d9b261dc0c55aae551417a353605ef4914" class='text-blue-700 hover:text-blue-500'>0x161c39d9b261dc0c55aae551417a353605ef4914</a></p>
      <div v-show="!connected" id="prepare">
        <button
          @click="onConnect"
          class="bg-black hover:bg-gray-700 text-white font-bold py-1 px-4 m-5"
          id="btn-connect"
        >
          Connect wallet
        </button>
      </div>

      <div v-show="connected">
        <p>
          <span
            id="selected-account"
            class="text-blue-500 px-4 py-2 border-4 border-blue-500"
          >
            {{ balance }} ETH | {{ account.substr(0, 8) }}</span
          >
          <button
            class="bg-black hover:bg-gray-700 text-white font-bold py-2 px-4 m-5 border-black"
            id="btn-disconnect"
            @click="onDisconnect"
          >
            Disconnect
          </button>
          <button
            id="mint-token"
            @click="show"
            class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 m-5"
          >
            Airdrop!
          </button>
        </p>
      </div>
    </div>
    <hr />
    <transition
      name="expand"
      @enter="enter"
      @after-enter="afterEnter"
      @leave="leave"
    >
      <div v-show="connected" class="py-4 px-4 max-w-lg m-auto">
        <h2 class="py-4 px-2">
          Mint For Your Love
        </h2>

        <div class="mb-4">
          <p>
            <a href="https://opensea.io/account" class="hover:text-blue-500"> You own
            <strong class="text-blue-500">{{ owns }}</strong> CouplePunks!</a>
          </p>
          <p>{{ totaltokens }} / 100000 Punks Minted.</p>
          <input
            v-model="amount"
            class=" appearance-none border rounded w-full py-5 px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline text-center"
            id="amount"
            type="text"
            onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
            placeholder="Amount to Mint"
            :disabled="!!loading"
          />
          <p class="py-2">
            Need {{ parseFloat(price * amount).toFixed(2) }} ETH to Mint
          </p>
          <button
            :disabled="loading || !start"
            id="mint-token"
            @click="onMint"
            :class="
              loading || !start || amount <= 0
                ? 'bg-gray-500 hover:bg-gray-700'
                : 'bg-red-500 hover:bg-red-700'
            "
            class="text-white font-bold m-4 py-4 px-8"
          >
            <img
              src="../assets/heart.png"
              alt=""
              srcset=""
              width="40"
              :class="loading ? 'rotate' : ''"
              style="display:inline"
            />
            Mint
          </button>
        </div>
        <div class="mb-4 loading" v-show="loading">
          Your Love Message is Sending
        </div>
        <div class="result">
          {{ message }}
        </div>
      </div>
    </transition>
    <div class="modal-bg bg-white absolute inset-x-0 top-10" v-show="showModal">
      <div class="modal-container border-8 border-black px-4 py-4">
        <div class="airdrop">
          <h2 class="py-4 px-2">
            Get Your Free CouplePunk
          </h2>
          <p>
            You have
            <span class="text-blue-500"> {{ availableAirdrops }} </span> Free
            CouplePunk to Claim !!
          </p>
          <button
            id="mint-token"
            @click="onClaim"
            :class="
              availableAirdrops > 0
                ? 'bg-red-500 hover:bg-red-700'
                : 'bg-gray-500 hover:bg-gray-700'
            "
            class="text-white font-bold m-4 py-4 px-8"
          >
            Claim
          </button>
          <button
            id="mint-token"
            @click="close"
            class="bg-black hover:bg-gray-700 text-white font-bold m-4 py-4 px-8"
          >
            Close
          </button>
          <p>{{ claimResult }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CouplepunksABI = [
  { inputs: [], stateMutability: "nonpayable", type: "constructor" },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "approved",
        type: "address",
      },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Approval",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "operator",
        type: "address",
      },
      { indexed: false, internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "ApprovalForAll",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address",
      },
      {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address",
      },
    ],
    name: "OwnershipTransferred",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      { indexed: true, internalType: "address", name: "from", type: "address" },
      { indexed: true, internalType: "address", name: "to", type: "address" },
      {
        indexed: true,
        internalType: "uint256",
        name: "tokenId",
        type: "uint256",
      },
    ],
    name: "Transfer",
    type: "event",
  },
  {
    inputs: [],
    name: "MAX_MINTABLE_AT_ONCE",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "approve",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "contractURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "endSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "freeRollMint",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "", type: "address" }],
    name: "freeRollPunks",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "getApproved",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_numToMint", type: "uint256" }],
    name: "getCostForMintingPunks",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "owner", type: "address" }],
    name: "getNumFreeRollPunks",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getPrice",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "_owner", type: "address" }],
    name: "getPunksBelongingToOwner",
    outputs: [{ internalType: "uint256[]", name: "", type: "uint256[]" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "receiver", type: "address" },
      { internalType: "uint256", name: "numRolls", type: "uint256" },
    ],
    name: "giveFreeRoll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "imageHash",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "address", name: "operator", type: "address" },
    ],
    name: "isApprovedForAll",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "isSaleOn",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_numToMint", type: "uint256" }],
    name: "mint",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [],
    name: "name",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "numTotalPunks",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "owner",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "tokenId", type: "uint256" }],
    name: "ownerOf",
    outputs: [{ internalType: "address", name: "", type: "address" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "punkcontractURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
      { internalType: "bytes", name: "_data", type: "bytes" },
    ],
    name: "safeTransferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "saleHasBeenStarted",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "operator", type: "address" },
      { internalType: "bool", name: "approved", type: "bool" },
    ],
    name: "setApprovalForAll",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "baseURI", type: "string" }],
    name: "setBaseURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "_contractURI", type: "string" }],
    name: "setContractURI",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "string", name: "_imageHash", type: "string" }],
    name: "setImageHash",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_newPrice", type: "uint256" }],
    name: "setPrice",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "startSale",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "bytes4", name: "interfaceId", type: "bytes4" }],
    name: "supportsInterface",
    outputs: [{ internalType: "bool", name: "", type: "bool" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "symbol",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "index", type: "uint256" }],
    name: "tokenByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "owner", type: "address" },
      { internalType: "uint256", name: "index", type: "uint256" },
    ],
    name: "tokenOfOwnerByIndex",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [{ internalType: "uint256", name: "_tokenId", type: "uint256" }],
    name: "tokenURI",
    outputs: [{ internalType: "string", name: "", type: "string" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "totalSupply",
    outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      { internalType: "address", name: "from", type: "address" },
      { internalType: "address", name: "to", type: "address" },
      { internalType: "uint256", name: "tokenId", type: "uint256" },
    ],
    name: "transferFrom",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [{ internalType: "address", name: "newOwner", type: "address" }],
    name: "transferOwnership",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawFailsafe",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [],
    name: "withdrawTeam",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const CouplepunksAddr = "0x161c39d9b261dc0c55aae551417a353605ef4914";

export default {
  name: "Mint",
  props: {
    msg: Function,
  },
  created() {
    const Web3Modal = window.Web3Modal.default;
    const providerOptions = {};
    this.web3Modal = new Web3Modal({
      cacheProvider: true, // optional
      providerOptions, // required
      disableInjectedProvider: false, // optional. For MetaMask / Brave / Opera.
    });
    this.evmChains = window.evmChains;

    let timer = setInterval(() => {
      if (+new Date() >= 1628514000000) {
        this.start = true;
        clearInterval(timer);
      }
    }, 1000);
  },
  data() {
    return {
      web3: null,
      Web3Modal: null,
      web3modal: null,
      evmChains: null,
      chainsName: "",
      account: "",
      provider: null,
      totaltokens: 0,
      availableAirdrops: 0,
      connected: false,
      couplepunksContract: null,
      amount: null,
      loading: false,
      price: 0,
      owns: 0,
      balance: 0,
      showModal: false,
      claimResult: "",
      start: false,
    };
  },
  methods: {
    enter(element) {
      const width = getComputedStyle(element).width;

      element.style.width = width;
      element.style.position = "absolute";
      element.style.visibility = "hidden";
      element.style.height = "auto";

      const height = getComputedStyle(element).height;

      element.style.width = null;
      element.style.position = null;
      element.style.visibility = null;
      element.style.height = 0;

      getComputedStyle(element).height;

      requestAnimationFrame(() => {
        element.style.height = height;
      });
    },
    afterEnter(element) {
      element.style.height = "auto";
    },
    leave(element) {
      const height = getComputedStyle(element).height;

      element.style.height = height;
      getComputedStyle(element).height;
      requestAnimationFrame(() => {
        element.style.height = 0;
      });
    },

    async show() {
      this.showModal = true;
    },
    async close() {
      this.showModal = false;
      this.claimResult = "";
      await this.refreshTokenData();
    },

    async onConnect() {
      this.msg();
      console.log("Opening a dialog", this.web3Modal);
      try {
        this.provider = await this.web3Modal.connect();
        this.web3 = new window.Web3(this.provider);
        this.couplepunksContract = new this.web3.eth.Contract(
          CouplepunksABI,
          CouplepunksAddr
        );
      } catch (e) {
        console.log("Could not get a wallet connection", e);
        return;
      }
      this.provider.on("accountsChanged", () => {
        this.fetchAccountData();
        this.refreshTokenData();
      });

      this.provider.on("chainChanged", () => {
        this.fetchAccountData();
        this.refreshTokenData();
      });
      this.provider.on("networkChanged", () => {
        this.fetchAccountData();
        this.refreshTokenData();
      });
      await this.refreshAccountData();
      setInterval(() => {
        this.refreshTokenData();
      }, 5000);
      this.refreshTokenData();
    },
    async refreshTokenData() {
      this.totaltokens = await this.couplepunksContract.methods
        .totalSupply()
        .call();
      this.availableAirdrops = await this.couplepunksContract.methods
        .getNumFreeRollPunks(this.account)
        .call();
      console.log(`airdrop: ${this.availableAirdrops}`);

      this.owns = await this.couplepunksContract.methods
        .balanceOf(this.account)
        .call();

      let price = await this.couplepunksContract.methods.getPrice().call();
      let hprice = this.web3.utils.fromWei(price, "ether");
      this.price = parseFloat(hprice).toFixed(4);
      console.log(this.price);
    },
    async onDisconnect() {
      this.msg();
      if (this.provider.close) {
        await this.provider.close();
      }
      await this.web3Modal.clearCachedProvider();
      this.provider = null;
      this.connected = false;
    },
    async onMint() {
      console.log("Mint Couplepunk NFT!!", this.provider, this.account);
      if (!this.amount) return;
      this.loading = true;
      try {
        let result = await this.couplepunksContract.methods
          .mint(this.web3.utils.toHex(this.amount))
          .send({
            from: this.account,
            value: this.web3.utils.toWei(
              this.price * this.amount + "",
              "ether"
            ),
          });
        this.loading = false;
        this.msg();
        console.log(result);
        await this.refreshTokenData();
      } catch (error) {
        this.loading = false;
      }
    },
    async onClaim() {
      console.log(`claiming ${this.availableAirdrops} free rolls`);
      if (this.availableAirdrops <= 0) return;
      try {
        let result = await this.couplepunksContract.methods
          .freeRollMint()
          .send({
            from: this.account,
          });
        this.claimResult = "Claim Successfully";
        console.log(result);
      } catch (error) {
        console.log(error);
        this.claimResult = "Claim Failed";
      }
    },
    async fetchAccountData() {
      const web3 = new window.Web3(this.provider);

      console.log("Web3 instance is", web3);

      const chainId = await web3.eth.getChainId();
      this.chainsName = this.evmChains.getChain(chainId).name;

      const accounts = await web3.eth.getAccounts();
      console.log("Got accounts", accounts);
      this.account = accounts[0];

      const rowResolvers = accounts.map(async (address) => {
        const balance = await web3.eth.getBalance(address);
        const ethBalance = web3.utils.fromWei(balance, "ether");
        this.balance = parseFloat(ethBalance).toFixed(4);
      });
      await Promise.all(rowResolvers);
    },
    async refreshAccountData() {
      await this.fetchAccountData();
      this.connected = true;
    },
  },
};
</script>

<style scoped>
* {
  will-change: height;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.expand-enter-active,
.expand-leave-active {
  transition: height 1s ease-in-out;
  overflow: hidden;
}

.expand-enter,
.expand-leave-to {
  height: 0;
}
h2 {
  font-size: 25px;
  color: #333;
  font-weight: bold;
  font-family: "8BIT WONDER Nominal";
}
.loading {
  font-weight: bold;
  font-family: "8BIT WONDER Nominal";
}
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
