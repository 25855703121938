<template>
  <div id="app">
    <Couplepunks />
  </div>
</template>
<script>
import Couplepunks from "./components/Couplepunks.vue";

export default {
  name: "App",
  created() {
    document.title = "CouplePunks | the Punks with Love"
  },
  components: {
    Couplepunks,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  font-family: 'LoveloBlack';
  font-weight: normal;
  
}
@font-face {
  font-family: "8BIT WONDER Nominal";
  font-style: normal;
  font-weight: normal;
  src: local("8BIT WONDER Nominal"), url("./assets/8-BIT WONDER.woff") format("woff");
}
@font-face {
  font-family: 'LoveloBlack';
    src:  url('./assets/LoveloBlack.ttf.woff') format('woff'),
    url('./assets/LoveloBlack.ttf.svg#LoveloBlack') format('svg'),
    url('./assets/LoveloBlack.ttf.eot');
    font-weight: normal;
    font-style: normal;
}
</style>
